const customerOrdersQuery = /* GraphQL */ `
	query getCustomerOrders($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			numberOfOrders
			orders(first: 20, reverse: true) {
				edges {
					cursor
					node {
						name
						canceledAt
						processedAt
						lineItems(first: 50) {
							edges {
								node {
									title
									variant {
										image {
											height
											width
											url
											altText
										}
										title
										product {
											productType
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
export default customerOrdersQuery;
