import { ReactNode, SVGAttributes, forwardRef, useState } from 'react';
import cn from 'classnames';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import Loading from '../../common/Loading/Loading';
import styles from './Icon.module.scss';

export type IconProps = {
	shape?: 'square' | 'circle' | 'wide-square' | 'floating' | 'fill';
	label: string;
	children: ReactNode;
	callback?: () => void;
	disabled?: boolean;
	pathFill?: SVGAttributes<SVGPathElement>['fill'];
	pathColor?: SVGAttributes<SVGPathElement>['stroke'];
	active?: boolean;
	hoverable?: boolean;
	className?: string;
	width?: number | string;
	height?: number | string;
};

const Icon = forwardRef<HTMLButtonElement | HTMLDivElement, IconProps>(
	({ shape = 'floating', children, callback, disabled = false, hoverable = false, className, label, ...rest }, ref) => {
		const [callbackIsLoading, setCallbackIsLoading] = useState(false);

		const classes = cn(styles.container, className, {
			[styles[shape]]: shape,
			[styles['disabled']]: disabled,
			[styles['hoverable']]: !!callback || hoverable,
		});

		if (!callback) {
			return (
				<AccessibleIcon.Root label={label} key={label + shape}>
					<div
						className={classes}
						ref={ref as React.MutableRefObject<HTMLDivElement>}
						role='img'
						aria-label={label}
						{...rest}
					>
						{children}
					</div>
				</AccessibleIcon.Root>
			);
		}

		return (
			<button
				key={label + shape}
				disabled={disabled}
				className={classes}
				ref={ref as React.MutableRefObject<HTMLButtonElement>}
				aria-label={label}
				onClick={async () => {
					if (!callback) return;
					setCallbackIsLoading(true);
					await callback();
					setCallbackIsLoading(false);
				}}
				{...rest}
			>
				{callbackIsLoading ? <Loading /> : children}
			</button>
		);
	}
);

Icon.displayName = 'Icon';

export default Icon;
