import { Image } from '@ts/shopify-storefront-api';
import { cleanGraphqlResponse, fetchStorefrontApi } from '..';
import { customerOrdersQuery } from '../queries';

export type CustomerOrdersResponse = {
	customer: {
		numberOfOrders: number;
		orders: {
			canceledAt: string;
			processedAt: string;
			lineItems: {
				title: string;
				variant: {
					image: Image;
					title: string;
					product: {
						productType: string;
					};
				};
			}[];
		}[];
	};
};

const getCustomerOrders = async (token: string) => {
	const dataToClean = await fetchStorefrontApi(customerOrdersQuery, { variables: { customerAccessToken: token } });
	const { customer } = cleanGraphqlResponse<CustomerOrdersResponse>(dataToClean);
	return customer;
};

export default getCustomerOrders;
