/* eslint-disable max-lines */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BUILD_FLOW_STEP_DIRECTION, BUILD_FLOW_STEPS, CUSTOMER_JOURNEYS, LENS_COLORS } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { NormalizedVariant } from '@ts/product';
import { BFContextProps, BFProviderProps } from '@ts/index';
import { useHasMounted, useSubscription } from '@utils/hooks';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { usePreviousBaseFrames } from '@services/shopify';
import { addAndDeleteVariantsFromDict, getFrameHandleFromColor, LOCALE_DICT } from '..';

const BFContext = createContext<BFContextProps>(null);

export function BFProvider({ children, props }: BFProviderProps) {
	const [journey] = useState(props.journey);
	const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const [collectionListSlug, setCollectionListSlug] = useState(COLLECTION_LISTS.BUILD_FLOW);
	const [frame] = useState(props.frame);
	const [frameColor, setFrameColor] = useState(props.frameColor);
	const [rxType, setRxType] = useState(props.rxType);
	const [sellingPlanId, setSellingPlanId] = useState(props.sellingPlanId);
	const [firstTopSubscription, setFirstTopSubscription] = useState<NormalizedVariant>();
	const [skipSubscription, setSkipSubscription] = useState(false);
	const [product, setProduct] = useState(props.product);
	const [readerRx, setReaderRx] = useState(props.readerRx ?? null);
	const rxOptions = props.rxOptions;
	const [lensPackage, setLensPackage] = useState(props.lensPackage);
	const [selectedLenses, setSelectedLenses] = useState(props.selectedLenses ?? [BASE_FRAME_LENS_OPTIONS.CR39]);
	const selectedLensesInVariantOptionForm = createBaseFrameVariant(selectedLenses);
	const lensColor = selectedLenses.find(lens => lens.includes('Sun'))?.split(' - ')?.[1] as LENS_COLORS;
	const { data: previousBaseFrames } = usePreviousBaseFrames(journey);
	const isReApplyLensesTest2Active = useFeatureIsOn('is-re-apply-lenses-ux2-test');

	const [step, setStep] = useState<BUILD_FLOW_STEPS>(props.step);
	const [stepChange, setStepChange] = useState<BUILD_FLOW_STEP_DIRECTION>(null);
	const [tops, setTops] = useState(props.tops ?? {});
	const [variantImages, setVariantImages] = useState(props.variantImages);

	const { locale, query } = useRouter();
	const isEditMode = Boolean(query?.edit);
	const countryCode = LOCALE_DICT[locale].countryCode;
	const { isSubscriptionActivated } = useSubscription();
	const hasPreviousBaseFrames = useMemo(
		() => previousBaseFrames?.length > 0 && isReApplyLensesTest2Active,
		[previousBaseFrames, isReApplyLensesTest2Active]
	);

	useEffect(() => {
		setSellingPlanId(props.sellingPlanId);
	}, [props.sellingPlanId]);

	useEffect(() => {
		if (props.productSubscriptionId) {
			const subscriptionProduct = props.subscriptionProducts.find(product => product.id === props.productSubscriptionId);
			setFirstTopSubscription(subscriptionProduct);
		}
	}, [props.productSubscriptionId]);

	const { data: variantPrices } = useBaseFrameVariantPrices(
		props.product.handle + `-${getFrameHandleFromColor(frameColor)}`,
		countryCode
	);

	const BaseFrameVariant = useBaseFrameVariant({
		'handle': props.product.handle + `-${getFrameHandleFromColor(frameColor)}`,
		'Color': frameColor,
		'Rx Type': rxType,
		'Lens': selectedLensesInVariantOptionForm,
		'country': countryCode,
	});

	const { data: selectedVariant } = BaseFrameVariant;
	const basePrice = selectedVariant?.variantBySelectedOptions?.price?.amount;

	let subtotal = parseInt(basePrice) + Object.values(tops).reduce((a, c) => a + c.price.amount, 0);
	const isMounted = useHasMounted();

	if (step >= BUILD_FLOW_STEPS.SUBSCRIPTION && !skipSubscription) {
		const subscriptionPrice =
			parseInt(firstTopSubscription?.sellingPlans.find(plan => plan.id === sellingPlanId)?.price.amount) || 0;
		subtotal = subtotal + subscriptionPrice;
	}

	useEffect(() => {
		const isEyeglasses = journey === CUSTOMER_JOURNEYS.EYEGLASSES;
		if (hasPreviousBaseFrames && !isEditMode) {
			isEyeglasses && setLensPackage(previousBaseFrames[0].lensPackage);
			isEyeglasses && setSelectedLenses(previousBaseFrames[0].lensType);
			setRxType(previousBaseFrames[0].rxType);
		}
	}, [
		previousBaseFrames,
		isReApplyLensesTest2Active,
		product,
		frame,
		frameColor,
		isMounted,
		hasPreviousBaseFrames,
		lensColor,
		isEditMode,
	]);

	useEffect(() => {
		if ('collectionListSlug' in props && journey === CUSTOMER_JOURNEYS.EYEGLASSES) {
			setCollectionListSlug(props.collectionListSlug);
		}
	}, [journey, props]);

	function handleCustomLens(lensOption: BASE_FRAME_LENS_OPTIONS) {
		let updatedSelections = [];
		if (selectedLenses.includes(lensOption) && lensOption !== BASE_FRAME_LENS_OPTIONS.CR39) {
			updatedSelections = selectedLenses.filter(option => option !== lensOption);
			setSelectedLenses(updatedSelections);
		} else {
			const isLensPremiumPlus = lensOption === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
			const isLensCR39 = lensOption === BASE_FRAME_LENS_OPTIONS.CR39;
			if (isSunglasses || (!isLensPremiumPlus && !isLensCR39)) {
				updatedSelections = [...selectedLenses];
			}
			updatedSelections = [...updatedSelections, lensOption];

			setSelectedLenses(updatedSelections);
		}
	}

	function handleTop(top: NormalizedVariant) {
		const newTops = addAndDeleteVariantsFromDict(top, tops);
		setTops(newTops);
	}

	// Step change handler
	useEffect(() => {
		const skipSubscription = !isSubscriptionActivated;

		if (stepChange === BUILD_FLOW_STEP_DIRECTION.NEXT) {
			setStepChange(null);
			const withInSteps = step + 1 < Object.keys(BUILD_FLOW_STEPS).length / 2;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;
			if (isSunglasses && step === BUILD_FLOW_STEPS.CUSTOMIZE && rxType === RX_TYPE.READERS && readerRx === null) return;

			if (isSunglasses && step + 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(skipSubscription ? BUILD_FLOW_STEPS.TOP_FRAMES : BUILD_FLOW_STEPS.SUBSCRIPTION);
				return;
			}

			if (step + 1 === BUILD_FLOW_STEPS.SUBSCRIPTION && skipSubscription) {
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				return;
			}

			setStep(step + 1);
		}

		if (stepChange === BUILD_FLOW_STEP_DIRECTION.PREV) {
			setStepChange(null);
			const withInSteps = step - 1 >= 0;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;

			if (isSunglasses && step - 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(BUILD_FLOW_STEPS.LENS);
				return;
			}

			if (step - 1 === BUILD_FLOW_STEPS.SUBSCRIPTION && skipSubscription) {
				setStep(skipCustomize ? BUILD_FLOW_STEPS.LENS : BUILD_FLOW_STEPS.CUSTOMIZE);
				return;
			}

			setStep(step - 1);
		}
	}, [stepChange]);

	useEffect(() => {
		if (rxType === RX_TYPE.READERS || rxType === RX_TYPE.NON_RX) {
			setSelectedLenses(current => current.filter(lens => lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS));
		}
	}, [rxType]);

	useEffect(() => {
		if (props.product) {
			setProduct(props.product);
		}
		if (step === BUILD_FLOW_STEPS.COLOR) {
			setFrameColor(props.frameColor);
			setTops(props.tops);
			setSelectedLenses(props.selectedLenses ?? [BASE_FRAME_LENS_OPTIONS.CR39]);
			setRxType(props.rxType);
			setReaderRx(props.readerRx);
			setLensPackage(props.lensPackage);
		}
	}, [props.product, props.frameColor, props.tops, props.selectedLenses, props.rxType, props.readerRx, props.lensPackage]);

	if (!isMounted) return;

	return (
		<BFContext.Provider
			value={{
				collectionListSlug,
				setCollectionListSlug,
				frame,
				frameColor,
				setFrameColor,
				journey,
				lensColor,
				rxType,
				setRxType,
				product,
				setProduct,
				readerRx,
				setReaderRx,
				rxOptions,
				selectedLenses,
				setSelectedLenses,
				step,
				setStep,
				setStepChange,
				subtotal,
				tops,
				handleCustomLens,
				handleTop,
				variantImages,
				setVariantImages,
				BaseFrameVariant,
				variantPrices: variantPrices || props.variantPrices,
				lensPackage,
				setLensPackage,
				isSubscriptionActivated,
				setSellingPlanId,
				sellingPlanId,
				setSkipSubscription,
				skipSubscription,
				subscriptionProduct: firstTopSubscription,
				setFirstTopSubscription,
				subscriptionProducts: props.subscriptionProducts,
				previousBaseFrames,
				hasPreviousBaseFrames,
			}}
		>
			{children}
		</BFContext.Provider>
	);
}

export function useBFContext() {
	return useContext(BFContext);
}
