import { useQuery } from '@tanstack/react-query';
import { PRODUCT_TYPES } from '@constants';
import { getCustomerToken } from '../customer-token';
import { getCustomerOrders } from '../operations';

const useBfroDiscount = () => {
	const customerAccessToken = getCustomerToken();

	return useQuery(
		['bfro-discount', customerAccessToken],
		async () => {
			const { orders } = await getCustomerOrders(customerAccessToken);
			const eligibleProducts: Set<string> = new Set([PRODUCT_TYPES.BASE_FRAME_SR, PRODUCT_TYPES.BASE_FRAME]);
			const yearAgo = new Date();
			yearAgo.setFullYear(yearAgo.getFullYear() - 1);
			let orderedBFOverOneYear = false;
			let orderedBFRecently = false;

			for (let i = 0; i < orders.length; i++) {
				const { canceledAt, lineItems = [], processedAt } = orders[i];
				const processedDate = new Date(processedAt);

				if (!!canceledAt || orderedBFOverOneYear || orderedBFRecently) {
					continue;
				}

				if (processedDate < yearAgo) {
					for (let j = 0; j < lineItems.length; j++) {
						if (orderedBFOverOneYear) {
							break;
						}
						if (eligibleProducts.has(lineItems[j].variant?.product?.productType)) {
							orderedBFOverOneYear = true;
						}
					}
				}
				if (processedDate > yearAgo) {
					for (let k = 0; k < lineItems.length; k++) {
						if (orderedBFRecently) {
							break;
						}
						if (eligibleProducts.has(lineItems[k].variant?.product?.productType)) {
							orderedBFRecently = true;
						}
					}
				}
			}

			return orderedBFOverOneYear && !orderedBFRecently;
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				if (!data) return;
			},
			onError: error => {
				console.error(error);
			},
		}
	);
};

export default useBfroDiscount;
