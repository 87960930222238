import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCustomer } from '@services/shopify';
import { CUSTOMER_JOURNEYS, PRODUCT_TYPES } from '@constants';
import { getOrders } from '@services/poms';
import { getShopifyIdFromGID, parseBaseFrameVariant } from '@utils/shopify';
import { PreviousBaseFrame } from '@ts/index';
import { RX_TYPE } from '@utils/constants/base-skus';

const usePreviousBaseFrames = (journey: CUSTOMER_JOURNEYS): UseQueryResult<PreviousBaseFrame[]> => {
	const { data } = useCustomer();
	const isReApplyLensesTest1Active = useFeatureIsOn('is-re-apply-lenses-ux1-test');
	const isReApplyLensesTest2Active = useFeatureIsOn('is-re-apply-lenses-ux2-test');
	const isDevTestActive = useFeatureIsOn('dev-test-flag');

	return useQuery(
		['previous-base-frame', data?.id, journey],
		async () => {
			if (!data?.id) return [];
			const shopifyId = getShopifyIdFromGID(data.id);
			const previousBaseFrames = [];
			let page = 1;
			let hasNextPage = true;
			while (hasNextPage) {
				const response = await getOrders({ id: shopifyId, page });
				response.orders.forEach(order => {
					const orderCompleted = order?.last_status.title === 'Delivered';
					if (orderCompleted || isDevTestActive) {
						order.line_items.forEach(item => {
							if (item.product_type == PRODUCT_TYPES.BASE_FRAME_SR) {
								const options = parseBaseFrameVariant(item.variant_title);
								const shouldAdd =
									journey == CUSTOMER_JOURNEYS.EYEGLASSES ? !options.lensColor : !!options.lensColor;
								if (journey === CUSTOMER_JOURNEYS.SUNGLASSES) {
									options.lensType = options.lensType.filter(
										lens => !lens.includes(`Sun - ${options.lensColor}`)
									);
								}
								options.rxType !== RX_TYPE.READERS &&
									shouldAdd &&
									previousBaseFrames.push({
										base: item,
										...options,
									});
							}
						});
					}
				});

				hasNextPage = Boolean(response.pagination?.next) && previousBaseFrames.length < 3;
				page += 1;
			}

			return previousBaseFrames || [];
		},
		{
			cacheTime: Number.POSITIVE_INFINITY,
			enabled: !!data?.id && (isReApplyLensesTest1Active || isReApplyLensesTest2Active),
			onSuccess: data => {
				if (!data) return;
			},
			onError: error => {
				console.error(error);
			},
		}
	);
};

export default usePreviousBaseFrames;
